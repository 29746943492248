import type { GetStaticProps } from "next";
import { HomePage, PageDocument, PageQuery } from "graphql/generated-types";
import {
  addApolloState,
  initializeApollo,
  Helmet,
  renderMeta,
} from "@neonjungle/headless";
import { PageProps, PreviewDataType } from "@/lib/types";
import PageContainer from "@/layouts/PageContainer";
import PageInner from "@/layouts/PageInner";
import BlockRouter, { BlockTypes } from "@/components/blocks/BlockRouter";
import Hero from "@/components/Hero";

export default function Home({ data }: { data: PageQuery }) {
  const site = data.site!;
  const home = data!.site!.page as HomePage;

  return (
    <PageContainer pageType={home?.__typename!}>
      <Helmet>{renderMeta(home, site)}</Helmet>
      <Hero page={home} />
      <PageInner>
        <BlockRouter blocks={home.body! as [BlockTypes]} />
      </PageInner>
    </PageContainer>
  );
}

export const getStaticProps: GetStaticProps = async (context) => {
  const apolloClient = initializeApollo();
  const { previewData } = context as { previewData: PreviewDataType };

  const { data } = await apolloClient.query({
    query: PageDocument,
    variables: {
      hostname: process.env.HOSTNAME,
      urlPath: "/",
      token: previewData?.previewToken,
    },
  });

  const pageProps = addApolloState(apolloClient, {
    props: {
      data,
    },
  }) as PageProps;

  pageProps.revalidate = 10;

  return pageProps;
};
